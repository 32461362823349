namespace $ {
	export class $hyoo_when extends $mol_book2 {
		
		/**
		 * ```tree
		 * Placeholder null
		 * ```
		 */
		Placeholder() {
			return null as any
		}
		
		/**
		 * ```tree
		 * store $mol_state_shared
		 * ```
		 */
		@ $mol_mem
		store() {
			const obj = new this.$.$mol_state_shared()
			
			return obj
		}
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * pages /
		 * 	<= Main
		 * 	<= Schedule
		 * ```
		 */
		pages() {
			return [
				this.Main(),
				this.Schedule()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Joined_name* $mol_check_box
		 * 	title <= joined_name*
		 * 	checked?next <=> joined_allow*?next
		 * ```
		 */
		@ $mol_mem_key
		Joined_name(id: any) {
			const obj = new this.$.$mol_check_box()
			
			obj.title = () => this.joined_name(id)
			obj.checked = (next?: any) => this.joined_allow(id, next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * new_hint @ \New meet
		 * ```
		 */
		new_hint() {
			return this.$.$mol_locale.text( '$hyoo_when_new_hint' )
		}
		
		/**
		 * ```tree
		 * new_link \
		 * ```
		 */
		new_link() {
			return ""
		}
		
		/**
		 * ```tree
		 * New_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		New_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * New_link $mol_link
		 * 	hint <= new_hint
		 * 	uri <= new_link
		 * 	sub / <= New_icon
		 * ```
		 */
		@ $mol_mem
		New_link() {
			const obj = new this.$.$mol_link()
			
			obj.hint = () => this.new_hint()
			obj.uri = () => this.new_link()
			obj.sub = () => [
				this.New_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Github $mol_link_source uri \https://github.com/hyoo-ru/when.hyoo.ru/
		 * ```
		 */
		@ $mol_mem
		Github() {
			const obj = new this.$.$mol_link_source()
			
			obj.uri = () => "https://github.com/hyoo-ru/when.hyoo.ru/"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lights $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lights() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * chat_seed \
		 * ```
		 */
		chat_seed() {
			return ""
		}
		
		/**
		 * ```tree
		 * chat_pages
		 * ```
		 */
		chat_pages() {
			return this.Chat().pages()
		}
		
		/**
		 * ```tree
		 * Chat $mol_chat
		 * 	seed <= chat_seed
		 * 	pages => chat_pages
		 * ```
		 */
		@ $mol_mem
		Chat() {
			const obj = new this.$.$mol_chat()
			
			obj.seed = () => this.chat_seed()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Schedule_icon $mol_icon_calendar
		 * ```
		 */
		@ $mol_mem
		Schedule_icon() {
			const obj = new this.$.$mol_icon_calendar()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Schedule_link $mol_link
		 * 	hint @ \Schedule
		 * 	arg * schedule \
		 * 	sub / <= Schedule_icon
		 * ```
		 */
		@ $mol_mem
		Schedule_link() {
			const obj = new this.$.$mol_link()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_when_Schedule_link_hint' )
			obj.arg = () => ({
				schedule: ""
			})
			obj.sub = () => [
				this.Schedule_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * descr?next \
		 * ```
		 */
		@ $mol_mem
		descr(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Descr $mol_textarea
		 * 	value?next <=> descr?next
		 * 	hint @ \Describe your meeting..
		 * ```
		 */
		@ $mol_mem
		Descr() {
			const obj = new this.$.$mol_textarea()
			
			obj.value = (next?: any) => this.descr(next)
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_when_Descr_hint' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * joined_names /
		 * ```
		 */
		joined_names() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Joined $mol_view sub <= joined_names
		 * ```
		 */
		@ $mol_mem
		Joined() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => this.joined_names()
			
			return obj
		}
		
		/**
		 * ```tree
		 * name?next \
		 * ```
		 */
		@ $mol_mem
		name(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Name $mol_string
		 * 	hint @ \Your name
		 * 	value?next <=> name?next
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_when_Name_hint' )
			obj.value = (next?: any) => this.name(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * join?next false
		 * ```
		 */
		@ $mol_mem
		join(next?: any) {
			if ( next !== undefined ) return next as never
			return false
		}
		
		/**
		 * ```tree
		 * Join $mol_check_box
		 * 	checked?next <=> join?next
		 * 	title @ \Join
		 * ```
		 */
		@ $mol_mem
		Join() {
			const obj = new this.$.$mol_check_box()
			
			obj.checked = (next?: any) => this.join(next)
			obj.title = () => this.$.$mol_locale.text( '$hyoo_when_Join_title' )
			
			return obj
		}
		
		/**
		 * ```tree
		 * Main $mol_page
		 * 	title @ \When to meet
		 * 	tools /
		 * 		<= New_link
		 * 		<= Github
		 * 		<= Lights
		 * 		<= Chat
		 * 		<= Schedule_link
		 * 	body /
		 * 		<= Descr
		 * 		<= Joined
		 * 	foot /
		 * 		<= Name
		 * 		<= Join
		 * ```
		 */
		@ $mol_mem
		Main() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_when_Main_title' )
			obj.tools = () => [
				this.New_link(),
				this.Github(),
				this.Lights(),
				this.Chat(),
				this.Schedule_link()
			] as readonly any[]
			obj.body = () => [
				this.Descr(),
				this.Joined()
			] as readonly any[]
			obj.foot = () => [
				this.Name(),
				this.Join()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Schedule_close_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Schedule_close_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Schedule_close $mol_link
		 * 	hint @ \Close panel
		 * 	arg * schedule null
		 * 	sub / <= Schedule_close_icon
		 * ```
		 */
		@ $mol_mem
		Schedule_close() {
			const obj = new this.$.$mol_link()
			
			obj.hint = () => this.$.$mol_locale.text( '$hyoo_when_Schedule_close_hint' )
			obj.arg = () => ({
				schedule: null as any
			})
			obj.sub = () => [
				this.Schedule_close_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * calendars_after* /
		 * ```
		 */
		calendars_after(id: any) {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * calendar_month* $mol_time_moment
		 * ```
		 */
		@ $mol_mem_key
		calendar_month(id: any) {
			const obj = new this.$.$mol_time_moment()
			
			return obj
		}
		
		/**
		 * ```tree
		 * day_bingo* false
		 * ```
		 */
		day_bingo(id: any) {
			return false
		}
		
		/**
		 * ```tree
		 * day_selected* false
		 * ```
		 */
		day_selected(id: any) {
			return false
		}
		
		/**
		 * ```tree
		 * day_click*?event null
		 * ```
		 */
		@ $mol_mem_key
		day_click(id: any, event?: any) {
			if ( event !== undefined ) return event as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Calendar* $mol_date_calendar
		 * 	month_moment <= calendar_month*
		 * 	day_holiday* <= day_bingo*
		 * 	day_selected* <= day_selected*
		 * 	day_click*?event <=> day_click*?event
		 * ```
		 */
		@ $mol_mem_key
		Calendar(id: any) {
			const obj = new this.$.$mol_date_calendar()
			
			obj.month_moment = () => this.calendar_month(id)
			obj.day_holiday = (id: any) => this.day_bingo(id)
			obj.day_selected = (id: any) => this.day_selected(id)
			obj.day_click = (id: any, event?: any) => this.day_click(id, event)
			
			return obj
		}
		
		/**
		 * ```tree
		 * List $mol_infinite
		 * 	after* <= calendars_after*
		 * 	Row* <= Calendar*
		 * ```
		 */
		@ $mol_mem
		List() {
			const obj = new this.$.$mol_infinite()
			
			obj.after = (id: any) => this.calendars_after(id)
			obj.Row = (id: any) => this.Calendar(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Schedule $mol_page
		 * 	title @ \Schedule
		 * 	tools / <= Schedule_close
		 * 	body / <= List
		 * ```
		 */
		@ $mol_mem
		Schedule() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_when_Schedule_title' )
			obj.tools = () => [
				this.Schedule_close()
			] as readonly any[]
			obj.body = () => [
				this.List()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * joined_name* \
		 * ```
		 */
		joined_name(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * joined_allow*?next true
		 * ```
		 */
		@ $mol_mem_key
		joined_allow(id: any, next?: any) {
			if ( next !== undefined ) return next as never
			return true
		}
	}
	
}

